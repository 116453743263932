import React from 'react'

export default () => (
  <footer>
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link 
      href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap" 
      rel="stylesheet"
      />
    <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400&display=swap" rel="stylesheet" /> 
  </footer>
)